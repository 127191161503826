import React, { useEffect } from "react";
import Layout from '../../components/layout/Layout'

const MerciPageAtelier = () => {

  return (
    <Layout>
      <section className="section">
        <div className="container">
          <div className="content">
            <h1>Merci pour votre réservation !</h1>
            <p>Un mail de confirmation va vous être envoyé, à bientot sur place ... ;)</p>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default MerciPageAtelier;
